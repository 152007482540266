import salary from "@/common/mock/salary.json";
import selfemployed from "@/common/mock/selfemployed.json";
import etc from "@/common/mock/etc.json";

export default {
  state: {
    selectedSampleType: "직장인",
    selectedJob: "직장인",
    baseDate: salary.request_info.req_info.ref_date,
    requestData: { info: salary.request_info, request_item: salary },
  },
  getters: {
    selectedJob(state) {
      return state.selectedJob;
    },
    selectedSampleType(state) {
      return state.selectedSampleType;
    },
    baseDate(state) {
      return state.baseDate;
    },
    requestInfo(state) {
      return state.requestData.info;
    },
    requestItem(state) {
      return state.requestData.request_item;
    },
  },
  mutations: {
    selectSampleType: function (state, payload) {
      state.selectedSampleType = payload;
      if (payload === "자영업") {
        state.requestData.info = selfemployed.request_info;
        state.requestData.request_item = selfemployed;
        state.baseDate = selfemployed.request_info.req_info.ref_date;
        state.selectedJob = "자영업";
      } else if (payload === "기타") {
        state.requestData.info = etc.request_info;
        state.requestData.request_item = etc;
        state.baseDate = etc.request_info.req_info.ref_date;
        state.selectedJob = "프리랜서";
      } else {
        state.requestData.info = salary.request_info;
        state.requestData.request_item = salary;
        state.baseDate = salary.request_info.req_info.ref_date;
        state.selectedJob = "직장인";
      }
    },
    selectJob: function (state, payload) {
      state.selectedJob = payload;
    },
    setupFromFile: function (state, payload) {
      payload;
      state.requestData.info = {
        req_info: {
          req_client_code: "31W000001",
          req_id: "abc_11_30305294526593_1_12_30305294559440_1",
          ref_date: "20190630",
        },
        user_info: {
          user_id: "11_30305294526593_1_12_30305294559440_1",
          gender: "1",
          name: "김갑수",
          birth_date: "",
          workplace: "삼성전자",
        },
        matching_info: [
          {
            matching_type: "1",
            matching_keyword: "홍길동",
          },
          {
            matching_type: "3",
            matching_keyword: "삼성SDS",
          },
        ],
      };
      state.requestData.request_item = {
        items: [
          {
            no: 600,
            bank_name: "국민은행",
            account_num: "acn_004_001",
            tran_date: "2019-02-27 23:15:31",
            tran_time: "231531",
            tran_type: "스마트출금",
            print_content: "홍길동",
            inout_type: "출금",
            inout_code: -1,
            tran_amt: 704,
            after_balance_amt: 0,
            category_code1: "WDTFIND01680000",
            level1: "출금",
            level2: "이체거래",
            level3: "개인",
            level4: "은행계좌",
            level5: null,
            level_concat: "출금_이체거래_개인_은행계좌_",
            target_inst_name: null,
            capt_name: "홍길동",
            capt_branch: null,
            capt_round: 0,
            capt_count: 0,
          },
          {
            no: 0,
            bank_name: "국민은행",
            account_num: "acn_006_001",
            tran_date: "2019-01-01 15:23:30",
            tran_time: "152330",
            tran_type: "전자금융",
            print_content: "12월급여",
            inout_type: "입금",
            inout_code: 1,
            tran_amt: 500000,
            after_balance_amt: 678090,
            category_code1: "DPICEIC00410000",
            level1: "입금",
            level2: "소득",
            level3: "근로소득",
            level4: "급여",
            level5: null,
            level_concat: "입금_소득_근로소득_급여_",
            target_inst_name: null,
            capt_name: null,
            capt_branch: null,
            capt_round: 0,
            capt_count: 0,
          },
          {
            no: 6,
            bank_name: "국민은행",
            account_num: "acn_006_001",
            tran_date: "2019-01-07 10:25:45",
            tran_time: "102545",
            tran_type: "기일출금",
            print_content: "59280904042836",
            inout_type: "출금",
            inout_code: -1,
            tran_amt: 418270,
            after_balance_amt: 331600,
            category_code1: "WDFIBNK00590000",
            level1: "출금",
            level2: "금융",
            level3: "은행업권",
            level4: "대출",
            level5: null,
            level_concat: "출금_금융_은행업권_대출_",
            target_inst_name: "국민은행",
            capt_name: null,
            capt_branch: null,
            capt_round: 0,
            capt_count: 0,
          },
          {
            no: 12,
            bank_name: "국민은행",
            account_num: "acn_006_001",
            tran_date: "2019-01-07 20:07:47",
            tran_time: "200747",
            tran_type: "CMS공동",
            print_content: "메리츠010116",
            inout_type: "출금",
            inout_code: -1,
            tran_amt: 60000,
            after_balance_amt: 271600,
            category_code1: "WDFIINS00880000",
            level1: "출금",
            level2: "금융",
            level3: "보험업권",
            level4: "보험료",
            level5: null,
            level_concat: "출금_금융_보험업권_보험료_",
            target_inst_name: "메리츠화재해상보험",
            capt_name: null,
            capt_branch: null,
            capt_round: 116,
            capt_count: 0,
          },
        ],
      };
      // var fileReader = new FileReader();
      // fileReader.onload = function () {
      //     var data = fileReader.result; // data <-- in this var you have the file data in Base64 format
      // console.log(data);
      // console.log(payload);
      // };
      // console.log(payload);
    },
  },
};
