import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/views/theme/Base"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/views/pages/Dashboard.vue"),
        },
        {
          path: "/filter",
          name: "filter",
          component: () => import("@/views/pages/filter/Filter.vue"),
          children: [
            {
              path: "transaction_history",
              name: "transaction_history",
              component: () =>
                import("@/views/pages/filter/TransactionHistory.vue"),
            },
            {
              path: "income",
              name: "income",
              component: () => import("@/views/pages/filter/Income.vue"),
            },
          ],
        },
        {
          path: "/main_info",
          name: "main_info",
          component: () => import("@/views/pages/main_info/MainInfo.vue"),
          children: [
            {
              path: "salary",
              name: "salary",
              component: () => import("@/views/pages/main_info/Salary.vue"),
            },
            {
              path: "sales",
              name: "sales",
              component: () => import("@/views/pages/main_info/Sales.vue"),
            },
            {
              path: "etc_income",
              name: "etc_income",
              component: () => import("@/views/pages/main_info/EtcIncome.vue"),
            },
            {
              path: "balance",
              name: "balance",
              component: () => import("@/views/pages/main_info/Balance.vue"),
            },
            {
              path: "over_due",
              name: "over_due",
              component: () => import("@/views/pages/main_info/Overdue.vue"),
            },
            {
              path: "inout_top",
              name: "inout_top",
              component: () => import("@/views/pages/main_info/InoutTop.vue"),
            },
          ],
        },
        {
          path: "/pay_info",
          name: "pay_info",
          component: () => import("@/views/pages/pay_info/PayInfo.vue"),
          children: [
            {
              path: "insurance",
              name: "insurance",
              component: () => import("@/views/pages/pay_info/Insurance.vue"),
            },
            {
              path: "saving",
              name: "saving",
              component: () => import("@/views/pages/pay_info/Saving.vue"),
            },
            {
              path: "loan",
              name: "loan",
              component: () => import("@/views/pages/pay_info/Loan.vue"),
            },
            {
              path: "card",
              name: "card",
              component: () => import("@/views/pages/pay_info/Card.vue"),
            },
            {
              path: "communication",
              name: "communication",
              component: () =>
                import("@/views/pages/pay_info/Communication.vue"),
            },
            {
              path: "dues",
              name: "dues",
              component: () => import("@/views/pages/pay_info/Dues.vue"),
            },
            {
              path: "rental",
              name: "rental",
              component: () => import("@/views/pages/pay_info/Rental.vue"),
            },
            {
              path: "death",
              name: "death",
              component: () => import("@/views/pages/pay_info/Death.vue"),
            },
            {
              path: "tax",
              name: "tax",
              component: () => import("@/views/pages/pay_info/Tax.vue"),
            },
          ],
        },
        {
          path: "/inout_info",
          name: "inout_info",
          component: () => import("@/views/pages/inout_info/InoutInfo.vue"),
          children: [
            {
              path: "insurance",
              name: "inout_insurance",
              component: () => import("@/views/pages/inout_info/Insurance.vue"),
            },
            {
              path: "banking",
              name: "inout_banking",
              component: () => import("@/views/pages/inout_info/Banking.vue"),
            },
            {
              path: "transfer",
              name: "inout_transfer",
              component: () => import("@/views/pages/inout_info/Transfer.vue"),
            },
            {
              path: "card",
              name: "inout_card",
              component: () => import("@/views/pages/inout_info/Card.vue"),
            },
            {
              path: "loan",
              name: "inout_loan",
              component: () => import("@/views/pages/inout_info/Loan.vue"),
            },
            {
              path: "stock",
              name: "inout_stock",
              component: () => import("@/views/pages/inout_info/Stock.vue"),
            },
            {
              path: "etc",
              name: "inout_etc",
              component: () => import("@/views/pages/inout_info/Etc.vue"),
            },
          ],
        },
        {
          path: "/table",
          name: "table",
          component: () =>
            import("@/views/pages/vuetify/tables/DataTables.vue"),
        },
        {
          path: "/builder",
          name: "builder",
          component: () => import("@/views/pages/Builder.vue"),
        },
        {
          path: "/vue-bootstrap",
          name: "vue-bootstrap",
          component: () =>
            import("@/views/pages/vue-bootstrap/VueBootstrap.vue"),
          children: [
            {
              path: "alert",
              name: "vue-bootstrap-alert",
              component: () => import("@/views/pages/vue-bootstrap/Alert.vue"),
            },
            {
              path: "badge",
              name: "vue-bootstrap-badge",
              component: () => import("@/views/pages/vue-bootstrap/Badge.vue"),
            },
            {
              path: "breadcrumb",
              name: "vue-bootstrap-breadcrumb",
              component: () =>
                import("@/views/pages/vue-bootstrap/Breadcrumb.vue"),
            },
            {
              path: "button",
              name: "vue-bootstrap-button",
              component: () => import("@/views/pages/vue-bootstrap/Button.vue"),
            },
            {
              path: "button-group",
              name: "vue-bootstrap-button-group",
              component: () =>
                import("@/views/pages/vue-bootstrap/ButtonGroup.vue"),
            },
            {
              path: "button-toolbar",
              name: "vue-bootstrap-button-toolbar",
              component: () =>
                import("@/views/pages/vue-bootstrap/ButtonToolbar.vue"),
            },
            {
              path: "card",
              name: "vue-bootstrap-card",
              component: () => import("@/views/pages/vue-bootstrap/Card.vue"),
            },
            {
              path: "carousel",
              name: "vue-bootstrap-carousel",
              component: () =>
                import("@/views/pages/vue-bootstrap/Carousel.vue"),
            },
            {
              path: "collapse",
              name: "vue-bootstrap-collapse",
              component: () =>
                import("@/views/pages/vue-bootstrap/Collapse.vue"),
            },
            {
              path: "dropdown",
              name: "vue-bootstrap-dropdown",
              component: () =>
                import("@/views/pages/vue-bootstrap/Dropdown.vue"),
            },
            {
              path: "embed",
              name: "vue-bootstrap-embed",
              component: () => import("@/views/pages/vue-bootstrap/Embed.vue"),
            },
            {
              path: "form",
              name: "vue-bootstrap-form",
              component: () => import("@/views/pages/vue-bootstrap/Form.vue"),
            },
            {
              path: "form-checkbox",
              name: "vue-bootstrap-form-checkbox",
              component: () =>
                import("@/views/pages/vue-bootstrap/FormCheckbox.vue"),
            },
            {
              path: "form-file",
              name: "vue-bootstrap-form-file",
              component: () =>
                import("@/views/pages/vue-bootstrap/FormFile.vue"),
            },
            {
              path: "form-group",
              name: "vue-bootstrap-form-group",
              component: () =>
                import("@/views/pages/vue-bootstrap/FormGroup.vue"),
            },
            {
              path: "form-input",
              name: "vue-bootstrap-form-input",
              component: () =>
                import("@/views/pages/vue-bootstrap/FormInput.vue"),
            },
            {
              path: "form-radio",
              name: "vue-bootstrap-form-radio",
              component: () =>
                import("@/views/pages/vue-bootstrap/FormRadio.vue"),
            },
            {
              path: "form-select",
              name: "vue-bootstrap-form-select",
              component: () =>
                import("@/views/pages/vue-bootstrap/FormSelect.vue"),
            },
            {
              path: "form-textarea",
              name: "vue-bootstrap-form-textarea",
              component: () =>
                import("@/views/pages/vue-bootstrap/FormTextarea.vue"),
            },
            {
              path: "image",
              name: "vue-bootstrap-image",
              component: () => import("@/views/pages/vue-bootstrap/Image.vue"),
            },
            {
              path: "input-group",
              name: "vue-bootstrap-input-group",
              component: () =>
                import("@/views/pages/vue-bootstrap/InputGroup.vue"),
            },
            {
              path: "jumbotron",
              name: "vue-bootstrap-jumbotron",
              component: () =>
                import("@/views/pages/vue-bootstrap/Jumbotron.vue"),
            },
            {
              path: "layout-grid-system",
              name: "vue-bootstrap-layout-grid-system",
              component: () =>
                import("@/views/pages/vue-bootstrap/LayoutGridSystem.vue"),
            },
            {
              path: "link",
              name: "vue-bootstrap-link",
              component: () => import("@/views/pages/vue-bootstrap/Link.vue"),
            },
            {
              path: "list-group",
              name: "vue-bootstrap-list-group",
              component: () =>
                import("@/views/pages/vue-bootstrap/ListGroup.vue"),
            },
            {
              path: "media",
              name: "vue-bootstrap-media",
              component: () => import("@/views/pages/vue-bootstrap/Media.vue"),
            },
            {
              path: "modal",
              name: "vue-bootstrap-modal",
              component: () => import("@/views/pages/vue-bootstrap/Modal.vue"),
            },
            {
              path: "nav",
              name: "vue-bootstrap-nav",
              component: () => import("@/views/pages/vue-bootstrap/Nav.vue"),
            },
            {
              path: "navbar",
              name: "vue-bootstrap-navbar",
              component: () => import("@/views/pages/vue-bootstrap/Navbar.vue"),
            },
            {
              path: "pagination",
              name: "vue-bootstrap-pagination",
              component: () =>
                import("@/views/pages/vue-bootstrap/Pagination.vue"),
            },
            {
              path: "pagination-nav",
              name: "vue-bootstrap-pagination-nav",
              component: () =>
                import("@/views/pages/vue-bootstrap/PaginationNav.vue"),
            },
            {
              path: "popover",
              name: "vue-bootstrap-popover",
              component: () =>
                import("@/views/pages/vue-bootstrap/Popover.vue"),
            },
            {
              path: "progress",
              name: "vue-bootstrap-progress",
              component: () =>
                import("@/views/pages/vue-bootstrap/Progress.vue"),
            },
            {
              path: "spinner",
              name: "vue-bootstrap-spinner",
              component: () =>
                import("@/views/pages/vue-bootstrap/Spinner.vue"),
            },
            {
              path: "table",
              name: "vue-bootstrap-table",
              component: () => import("@/views/pages/vue-bootstrap/Table.vue"),
            },
            {
              path: "tabs",
              name: "vue-bootstrap-tabs",
              component: () => import("@/views/pages/vue-bootstrap/Tabs.vue"),
            },
            {
              path: "toasts",
              name: "vue-bootstrap-toasts",
              component: () => import("@/views/pages/vue-bootstrap/Toasts.vue"),
            },
            {
              path: "tooltip",
              name: "vue-bootstrap-tooltip",
              component: () =>
                import("@/views/pages/vue-bootstrap/Tooltip.vue"),
            },
          ],
        },
        {
          path: "/vuetify",
          name: "vuetify",
          component: () => import("@/views/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "alerts",
              name: "vuetify-alerts",
              component: () => import("@/views/pages/vuetify/Alerts.vue"),
            },
            {
              path: "avatars",
              name: "vuetify-avatars",
              component: () => import("@/views/pages/vuetify/Avatars.vue"),
            },
            {
              path: "badges",
              name: "vuetify-badges",
              component: () => import("@/views/pages/vuetify/Badges.vue"),
            },
            {
              path: "buttons",
              name: "vuetify-buttons",
              component: () => import("@/views/pages/vuetify/Buttons.vue"),
            },
            {
              path: "calendars",
              name: "vuetify-calendars",
              component: () => import("@/views/pages/vuetify/Calendars.vue"),
            },
            {
              path: "cards",
              name: "vuetify-cards",
              component: () => import("@/views/pages/vuetify/Cards.vue"),
            },
            {
              path: "chips",
              name: "vuetify-chips",
              component: () => import("@/views/pages/vuetify/Chips.vue"),
            },
            {
              path: "dialog",
              name: "vuetify-dialog",
              component: () => import("@/views/pages/vuetify/Dialog.vue"),
            },
            {
              path: "autocompletes",
              name: "vuetify-autocompletes",
              component: () =>
                import("@/views/pages/vuetify/forms/Autocompletes.vue"),
            },
            {
              path: "file-inputs",
              name: "vuetify-file-inputs",
              component: () =>
                import("@/views/pages/vuetify/forms/FileInputs.vue"),
            },
            {
              path: "forms",
              name: "vuetify-forms",
              component: () => import("@/views/pages/vuetify/forms/Forms.vue"),
            },
            {
              path: "selection-controls",
              name: "vuetify-selection-controls",
              component: () =>
                import("@/views/pages/vuetify/forms/SelectionControls.vue"),
            },
            {
              path: "selects",
              name: "vuetify-selects",
              component: () =>
                import("@/views/pages/vuetify/forms/Selects.vue"),
            },
            {
              path: "textareas",
              name: "vuetify-textareas",
              component: () =>
                import("@/views/pages/vuetify/forms/Textareas.vue"),
            },
            {
              path: "text-fields",
              name: "vuetify-text-fields",
              component: () =>
                import("@/views/pages/vuetify/forms/TextFields.vue"),
            },
            {
              path: "simple-tables",
              name: "vuetify-simple-tables",
              component: () =>
                import("@/views/pages/vuetify/tables/SimpleTables.vue"),
            },
            {
              path: "data-tables",
              name: "vuetify-data-tables",
              component: () =>
                import("@/views/pages/vuetify/tables/DataTables.vue"),
            },
            {
              path: "tabs",
              name: "vuetify-tabs",
              component: () => import("@/views/pages/vuetify/Tabs.vue"),
            },
            {
              path: "timelines",
              name: "vuetify-timelines",
              component: () => import("@/views/pages/vuetify/Timelines.vue"),
            },
            {
              path: "tooltips",
              name: "vuetify-tooltips",
              component: () => import("@/views/pages/vuetify/Tooltips.vue"),
            },
            {
              path: "treeview",
              name: "vuetify-treeview",
              component: () => import("@/views/pages/vuetify/Treeview.vue"),
            },
          ],
        },
        {
          path: "/wizard",
          name: "wizard",
          component: () => import("@/views/pages/wizard/Wizard.vue"),
          children: [
            {
              path: "wizard-1",
              name: "wizard-1",
              component: () => import("@/views/pages/wizard/Wizard-1.vue"),
            },
            {
              path: "wizard-2",
              name: "wizard-2",
              component: () => import("@/views/pages/wizard/Wizard-2.vue"),
            },
            {
              path: "wizard-3",
              name: "wizard-3",
              component: () => import("@/views/pages/wizard/Wizard-3.vue"),
            },
            {
              path: "wizard-4",
              name: "wizard-4",
              component: () => import("@/views/pages/wizard/Wizard-4.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/views/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/views/pages/error/Error-1.vue"),
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/views/pages/error/Error-2.vue"),
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/views/pages/error/Error-3.vue"),
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/views/pages/error/Error-4.vue"),
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/views/pages/error/Error-5.vue"),
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/views/pages/error/Error-6.vue"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/views/pages/error/Error-1.vue"),
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
